<template>
  <v-card class="py-5">
    <v-card-text class="popup-background">
      <div class="text-h4 primary--text text-center mt-5">
        บันทึกใบแจ้งหนี้เรียบร้อยแล้ว
      </div>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <div class="text-h4 success--text mt-15">
            <img
              width="150"
              src="@/assets/images/check-success.png"
              alt="check"
            />
          </div>
          <div class="text-h5 success--text mt-5">บันทึกเป็นใบแจ้งหนี้แล้ว</div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn
        outlined
        class="px-5 mr-10"
        color="primary"
        elevation="0"
        @click="print()"
        v-if="documentType == 'invoice'"
      >
        พิมพ์ใบแจ้งหนี้ <v-icon right>mdi-printer</v-icon>
      </v-btn>
      <slot></slot>
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()"
        >กลับ</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    item_id: {
      required: true,
      type: String,
      default: null,
    },
    documentType: {
      type: String,
      default: 'invoice',
    },
    id: {
      required: false,
      type: String,
      default: '',
    },
  },
  methods: {
    async print() {
      // this.$router.push({
      //   name: `document-${this.documentType}-update`,
      //   params: { id: this.item_id },
      //   query: { print: true }
      // });
      let body = {
        token: this.$jwt.sign({ document_id: this.id }, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      console.log(body);
      await this.$axios
        .post(`${this.$baseUrl}/document/open_quotation_bill`, body)
        .then(async (res) => {
          console.log(res.result);
          // this.$router.push({
          //   name: `document-${this.documentType}-update`,
          //   params: { id: res.result },
          //   query: { print: true }
          // });
          this.$router.push({
            name: `document-${this.documentType}`,
            query: { _id: res.result },
          });
          this.$emit('success');
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    async close() {
      let body = {
        token: this.$jwt.sign({ document_id: this.id }, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      console.log(body);
      await this.$axios
        .post(`${this.$baseUrl}/document/open_quotation_bill`, body)
        .then(async (res) => {
          console.log(res.result);
          this.$router.push({
            name: `document-${this.documentType}-update`,
            params: { id: res.result },
            query: { print: false },
          });
          this.$emit('success');
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
  },
};
</script>
